import {useEffect} from 'react';

export const AboutUs = () => {
    useEffect(() => {
        const updatePageTitle = () => {
            document.title = "ABOUT US | Cricket8";
        };
        updatePageTitle();
        return () => {
        };
    }, []);
    return (
        <div id="content" className="min-h-[75vh] mt-[0px] md:mt-[20px] p-[10px]">
            <h2 className="text-[14px] md:text-[20px]">Cricket8</h2>
            <h1 className="text-[30px] md:text-[55px]">ABOUT</h1>
            {/*IT&apos;S A COLLECTION OF INCREDIBLE*/}
            <div className='font-chakra font-[#fff] text-[14px] mt-[30px]'>
                <p className='pb-[20px]'>A Community Built by Cricket Fans, for Cricket Fans.</p>
                <p className='pb-[20px]'>We&apos;re not just another sports platform—we&apos;re a growing community of
                    <b> 800,000 cricket fans</b> who live and
                    breathe the game. Built by die-hard cricket enthusiasts, statisticians, traders and technologists,
                    our mission is simple: <b>bring fans closer to the game they love</b> through high-quality gaming,
                    real-time engagement, and thrilling interactive experiences.</p>
                <h1>Our Products</h1>
                <p className='pb-[20px]'>
                    We believe cricket isn&apos;t just a sport—it&apos;s a way of life. That&apos;s why we create
                    <b> premium, real-time products</b> that keeps our fans informed and entertained, whether
                    they&apos;re following a nail-biting match or reliving iconic cricket moments. Our platform offers:
                </p>
                <div>
                    <ul className='list-disc pl-[25px]'>
                        <li><b>Live Gaming</b> – ball by ball, over by over and match by match, every market covered.
                        </li>
                        <li><b>Live Sports Data</b> – Instant updates, scores, and in-depth stats.</li>
                        <li><b>Live Streaming Commentary</b> – Bringing the action to life, ball by ball.</li>
                        <li><b>Exclusive Videos & Podcasts</b> – Expert insights, match analysis, and behind-the-scenes
                            content.
                        </li>
                    </ul>
                </div>
                <br/>
                <p className='pb-[20px]'>Play. Compete. Win (Bragging Rights).</p>
                <p>Beyond content, we bring fans even closer to the action through our <b>growing suite of original
                    games</b>.
                    Designed for cricket lovers, our games capture the thrill of predicting outcomes - without the
                    risk.</p>
                <br/>
                <div>
                    <ul className='list-disc pl-[25px]'>
                        <li><b>No gambling. Just fun.</b> Our games let you <b>compete, strategize, and challenge your
                            friends</b> without wagering real money.
                        </li>
                        <li><b>Virtual Coins, Real Excitement.</b> Players can buy Rubies and convert them into virtual
                            coins, which they can use to play, compete, and climb the leaderboards.
                        </li>
                    </ul>
                </div>
                <br/>
                <h1>Our Fans Are at the Heart of Everything We Do</h1>
                <p>This isn&apos;t just our platform — it&apos;s <b>your</b> home of cricket entertainment. Whether
                    you&apos;re here for the latest match updates, expert commentary, or to test your skills in our
                    games, our goal is to make sure you <b>fall in love with the experience</b> and keep coming back to
                    play with us.</p>
                <br/>
                <p>Join us and be part of the ultimate cricket community!</p>
            </div>
        </div>
    );
};
export default AboutUs;
